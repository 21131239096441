exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aptitudes-js": () => import("./../../../src/pages/aptitudes.js" /* webpackChunkName: "component---src-pages-aptitudes-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-proyectos-js": () => import("./../../../src/pages/proyectos.js" /* webpackChunkName: "component---src-pages-proyectos-js" */),
  "component---src-pages-rollers-js": () => import("./../../../src/pages/rollers.js" /* webpackChunkName: "component---src-pages-rollers-js" */),
  "component---src-pages-tu-web-js": () => import("./../../../src/pages/tu-web.js" /* webpackChunkName: "component---src-pages-tu-web-js" */),
  "component---src-templates-templateproyects-js": () => import("./../../../src/templates/templateproyects.js" /* webpackChunkName: "component---src-templates-templateproyects-js" */)
}

